.container {
    width: 400px;
}
.letter{
    border: 1px solid #ccc;
    width: 34px;
    height: 34px;
    text-align: center;
    line-height: 34px;
    font-size: 24px;
    margin-left: -1px;
}
.flex {
    display: flex;
}
.flex-wrap {
    margin-top: 10px;
    flex-wrap: wrap;
    gap:5px;
}
.button{
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    height: 34px;
    width: 34px;
    text-align: center;
    cursor: pointer;
}
.guessed,.hidden  {
    display: none;
}
